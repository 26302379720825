export default {
    init(filterCategory: HTMLElement) {
        const dropdownWrappers =
            filterCategory.querySelectorAll('.dropdown-wrapper');

        if (!dropdownWrappers) return;

        dropdownWrappers.forEach((wrapper) => {
            let checked = 0;
            const dropdownButton = wrapper.querySelector('.dropdown-button');
            const dropdownContent = wrapper.querySelector('.dropdown-content');
            const checkBoxes = wrapper.querySelectorAll(
                '.dropdown-bullet input'
            ) as NodeListOf<HTMLInputElement>;

            if (!dropdownButton || !dropdownContent || !checkBoxes) return;

            const content = document.querySelector('.content');
            if (!content) return;

            const backdropArea = document.querySelector('.backdrop-area');

            dropdownButton.addEventListener('click', () => {
                if (dropdownContent.classList.contains('hidden')) {
                    // Open Drodown
                    dropdownContent.classList.remove('hidden');
                    dropdownButton.classList.add('active-dropdown');

                    if (backdropArea) {
                        backdropArea.classList.remove('hidden');

                        backdropArea.addEventListener('click', () => {
                            if (
                                dropdownButton.classList.contains(
                                    'active-dropdown'
                                )
                            ) {
                                dropdownContent.classList.add('hidden');
                                dropdownButton.classList.remove(
                                    'active-dropdown'
                                );

                                if (checked > 0) {
                                    dropdownButton.classList.add(
                                        'selected-state'
                                    );
                                }
                                backdropArea.classList.add('hidden');
                            }
                        });
                    }
                } else if (
                    dropdownButton.classList.contains('active-dropdown')
                ) {
                    // Close Dropdown
                    dropdownContent.classList.add('hidden');
                    dropdownButton.classList.remove('active-dropdown');
                    if (checked <= 0) {
                        if (
                            dropdownButton.classList.contains('active-dropdown')
                        ) {
                            dropdownButton.classList.remove('selected-state');
                        }
                    }

                    if (backdropArea) {
                        backdropArea.classList.add('hidden');
                    }
                }
            });

            const countBox = wrapper.querySelector('.count');

            if (!countBox) return;

            checkBoxes.forEach((checkBox) => {
                // Set count in dropdownbutton
                checkBox.addEventListener('click', () => {
                    const dropdownWrapper =
                        checkBox.closest('.dropdown-wrapper');

                    if (checkBox.checked) {
                        // add selected-state class once checkbox is checked
                        checkBox.classList.add('option-checked');
                        checked++;
                        countBox.innerHTML = '(' + checked + ')';
                        if (
                            dropdownWrapper?.classList.contains(
                                'selected-state'
                            )
                        ) {
                            // do not add class again
                        } else {
                            dropdownWrapper?.classList.add('selected-state');
                        }
                    } else {
                        checkBox.classList.remove('option-checked');
                        checked--;
                        countBox.innerHTML = '(' + checked + ')';
                    }

                    if (checked <= 0) {
                        countBox.innerHTML = '';
                        // if no checkbox is checked, remove selected-state class
                        if (
                            dropdownWrapper?.classList.contains(
                                'selected-state'
                            )
                        ) {
                            dropdownWrapper?.classList.remove('selected-state');
                        }
                    }
                });
            });
        });
    }
};
