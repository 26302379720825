import swiper from './ts/swiper';
import './app.scss';
import 'lazysizes';
import htmx from 'htmx.org';
import magazineOverview from './ts/magazine-filter-buttons';
import filterDropdown from './ts/filter-dropdown';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const magazine = document.getElementById('magazine');
    if (magazine) {
        magazineOverview.init();
    }

    const titleClickDivs = document.getElementsByClassName('title-click-div');
    if (titleClickDivs.length) {
        import('./ts/accordion').then((accordionEntry) => {
            accordionEntry.default.init();
        });
    }

    const filterCategories = document.querySelectorAll(
        '.filter-categories'
    ) as NodeListOf<HTMLElement>;
    if (filterCategories.length) {
        filterCategories.forEach((filterCategory) => {
            filterDropdown.init(filterCategory);
        });
    }

    const menu = document.getElementById('menu');
    if (menu) {
        import('./ts/navigation').then((navigation) => {
            navigation.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    const kpiSections = document.querySelectorAll(
        '.kpis'
    ) as NodeListOf<HTMLElement>;
    if (kpiSections.length) {
        import('./ts/kpis-increase-numbers').then((kpiIncreaseNumbers) => {
            kpiSections.forEach((container) => {
                kpiIncreaseNumbers.default.init(container);
            });
        });
    }

    const quoteSections = document.querySelectorAll(
        '.quote'
    ) as NodeListOf<HTMLElement>;
    if (quoteSections.length) {
        import('./ts/quote-text-split').then((quoteTextSplit) => {
            quoteSections.forEach((section) => {
                setTimeout(function () {
                    quoteTextSplit.default.init(section);
                }, 300);
            });
        });
        import('./ts/quote-intro').then((quoteIntro) => {
            quoteSections.forEach((section) => {
                setTimeout(function () {
                    quoteIntro.default.init(section);
                }, 300);
            });
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(
                    swiperElement,
                    JSON.parse(swiperElement.dataset.swiperOptions)
                );
            });
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    // no element request because element will be generated via js
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const youtubeVideoContainers = document.querySelectorAll(
        '.youtube-video-container'
    ) as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        import('./ts/youtube-video').then((youtubeVideo) => {
            youtubeVideoContainers.forEach((container) => {
                youtubeVideo.default.init(container);
            });
        });
    }

    const videoSectionContainers = document.querySelectorAll(
        '.video-section'
    ) as NodeListOf<HTMLElement>;
    if (videoSectionContainers.length) {
        import('./ts/video-section').then((videoSection) => {
            videoSectionContainers.forEach((section) => {
                videoSection.default.init(section);
            });
        });
    }

    const cardOverlaySection = document.querySelectorAll(
        '.product-overlay-cards'
    ) as NodeListOf<HTMLElement>;
    if (cardOverlaySection.length) {
        import('./ts/card-overlay').then((cardOverlay) => {
            cardOverlaySection.forEach((container) => {
                cardOverlay.default.init(container);
            });
        });
    }

    const umbrellaBarVariantSliders = document.querySelectorAll(
        '.umbrellabar-variant-slider'
    ) as NodeListOf<HTMLElement>;
    if (umbrellaBarVariantSliders.length) {
        import('./ts/umbrella-bar-variant-slider').then((variantSlider) => {
            umbrellaBarVariantSliders.forEach((container) => {
                variantSlider.default.init(container);
            });
        });
    }

    const cardOverlayContainersMobile = document.querySelectorAll(
        '.product-overlay-cards'
    ) as NodeListOf<HTMLElement>;
    if (cardOverlayContainersMobile.length) {
        import('./ts/card-overlay-mobile').then((cardOverlayMobile) => {
            cardOverlayContainersMobile.forEach((container) => {
                cardOverlayMobile.default.init(container);
            });
        });
    }

    const heroHomeSections = document.querySelectorAll(
        '.hero-home'
    ) as NodeListOf<HTMLElement>;
    if (heroHomeSections.length) {
        import('./ts/hero-video').then((heroVideo) => {
            heroHomeSections.forEach((section) => {
                heroVideo.default.init(section);
            });
        });
        import('./ts/hero-video-text-split').then((heroHomeTextSplit) => {
            heroHomeSections.forEach((section) => {
                setTimeout(function () {
                    heroHomeTextSplit.default.init(section);
                }, 300);
            });
        });
        import('./ts/hero-home-intro').then((heroHomeIntro) => {
            heroHomeSections.forEach((section) => {
                setTimeout(function () {
                    heroHomeIntro.default.init(section);
                }, 300);
            });
        });
    }

    const projectTeaserSections = document.querySelectorAll(
        '.project-teaser'
    ) as NodeListOf<HTMLElement>;
    if (projectTeaserSections.length) {
        import('./ts/project-teaser').then((projectTeaser) => {
            projectTeaserSections.forEach((section) => {
                projectTeaser.default.init(section);
            });
        });
    }

    const slidingBanners = document.querySelectorAll(
        '.sliding-banner'
    ) as NodeListOf<HTMLElement>;
    if (slidingBanners.length) {
        slidingBanners.forEach((component) => {
            import('./ts/banner').then((slidingBanner) => {
                slidingBanner.default.init(component);
            });
        });
    }

    htmx.on('htmx:afterSwap', function () {
        // recreate js after sprig reloads something
        const allSwiper = document.querySelectorAll(
            '.swiper-container'
        ) as NodeListOf<HTMLElement>;
        if (allSwiper.length) {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.init(
                    swiperElement,
                    JSON.parse(swiperElement.dataset.swiperOptions)
                );
            });
        }

        const magazine = document.getElementById('magazine');
        if (magazine) {
            magazineOverview.init();
        }

        // const filterCategories = document.querySelectorAll(
        //     '.filter-categories'
        // ) as NodeListOf<HTMLElement>;
        // if (filterCategories.length) {
        //     filterCategories.forEach((filterCategory) => {
        //         filterDropdown.init(filterCategory);
        //     });
        // }
    });
})();
