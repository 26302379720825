export default {
    init() {
        const filterButtons = document.querySelectorAll(
            '.filter-button-magazine'
        ) as NodeListOf<HTMLElement>;

        if (filterButtons) {
            filterButtons.forEach((button: HTMLElement) => {
                button.addEventListener('click', function () {
                    // remove active class when clicking same button again
                    if (button.classList.contains('active')) {
                        button.classList.remove('active');
                    } else {
                        button.classList.add('active');
                    }

                    if (button.classList.contains('swiper-slide-active')) {
                        button.classList.remove('swiper-slide-active');
                    } else {
                        button.classList.add('swiper-slide-active');
                    }

                    const activeButtons = document.querySelectorAll(
                        '.filter-button-magazine.active'
                    );

                    activeButtons.forEach((activeButton) => {
                        activeButton.classList.remove('active');
                    });

                    const activeSlideButtons = document.querySelectorAll(
                        '.filter-button-magazine.swiper-slide-active'
                    );

                    activeSlideButtons.forEach((activeButton) => {
                        activeButton.classList.remove('swiper-slide-active');
                    });

                    button.classList.add('swiper-slide-active');
                    button.classList.add('active');
                });
            });
        }
    }
};
